/* eslint global-require: "off" */

import React, {useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';

import {
  PROJECT_ADMIN_CAPABILITY,
  OPERATIONS_PORTAL,
  BI_REPORT_2,
} from './shared/util/allowed';
import AuthenticatedRoute from './shared/components/authenticatedRoute';
import {getFullUserById} from './api/users';
import Viewport from './shared/components/viewport';
import GlobalErrorPage from './pages/globalErrorPage';
import {getHostEnv} from './shared/util/general';
import {incidentsHosts} from './shared/components/navigationBar/utils';

import Admin from './pages/admin';
import Login from './pages/auth/login';
import Sites from './pages/sites';
import Clips2 from './pages/clips2';
import Notifications from './pages/notifications';
import ResetPassword from './pages/resetPassword';
import ExternalRedirect from './pages/externalRedirect';
import ForgotPassword from './pages/forgotPassword';
import ExternalShares from './pages/externalShares';
import LiveMonitor from './pages/liveMonitor';
import OrderStatus from './pages/orderStatus';
import ReportProblem from './pages/reportProblem';
import Reports from './pages/reports';
import PartnerEmbed from './pages/partnerEmbed';
import HardwareHealth from './pages/hardwareHealth';
import SmartSiteProtection from './pages/smartSiteProtection';
import Dashboard from './pages/dashboard';
import LandingPage from './pages/landingPage';
import Clips from './pages/clips/index';
import {withFirebaseNotifications} from './shared/components/firebase';
import PageIFrameWrapper from './pages/pageIFrameWrapper';
import Home from './pages/home';

const AuthRoute = (props) => (
  <AuthenticatedRoute
    {...props}
    authenticate={getFullUserById}
    redirectTo="/ui/auth/login"
  />
);

const Routes = ({firebaseClient, setNotificationAlert}) => {
  const notificationHandler = (event) => {
    setNotificationAlert({
      title: event?.data?.title,
      body: event?.data?.body,
      alarmId: event?.data?.alert_id,
      siteId: event?.data?.site_id,
    });
  };

  useEffect(() => {
    // setup message listener for firebase notifications
    firebaseClient.onMessageListener(notificationHandler);
    return () => {
      firebaseClient.removeOnMessageListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Viewport>
      <Switch>
        <Route exact path="/auth/login" component={Login} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route
          exact
          path="/external-clips/:shareToken"
          component={ExternalShares}
        />
        <AuthRoute exact path="/partner-embed" component={PartnerEmbed} />
        <AuthRoute exact path="/report-problem" component={ReportProblem} />
        <AuthRoute path="/admin" component={Admin} />
        <AuthRoute path="/sites" component={Sites} />
        <AuthRoute path="/clips2" component={Clips2} />
        <AuthRoute path="/loss-prevention" component={Reports} />
        <AuthRoute path="/operations" component={Reports} />
        <AuthRoute path="/reports" component={Reports} />
        <AuthRoute
          path="/notifications/:notificationId/tasks/:taskId"
          component={Notifications}
        />
        <AuthRoute exact path="/" component={LandingPage} />
        <AuthRoute path="/ssp" component={SmartSiteProtection} />
        <AuthRoute path="/home" component={Home} />
        <AuthRoute path="/dashboard" component={Dashboard} />
        <AuthRoute
          path="/incidents"
          component={() =>
            PageIFrameWrapper(
              '',
              'allow-scripts allow-same-origin allow-forms allow-modals allow-downloads allow-popups',
              [],
              'incidents',
              'Incident Management',
              incidentsHosts[getHostEnv()] || 'https://tsappdev1.envysion.com',
            )
          }
        />
        <AuthRoute path="/live-monitor" component={LiveMonitor} />
        <AuthRoute path="/order-status" component={OrderStatus} />
        <AuthRoute path="/clips" component={Clips} />
        <AuthRoute path="/hardware-health" component={HardwareHealth} />
        <AuthRoute
          path="/samples/report"
          component={() =>
            PageIFrameWrapper(
              '/app/#/samples/report',
              'allow-scripts allow-same-origin allow-downloads allow-popups allow-modals',
              [PROJECT_ADMIN_CAPABILITY],
              'samples-report',
              'Samples',
            )
          }
        />
        <AuthRoute
          path="/preferences/notifications"
          component={() =>
            PageIFrameWrapper(
              '/app/#/preferences/notifications',
              'allow-scripts allow-same-origin allow-downloads allow-popups allow-modals',
              [BI_REPORT_2],
              'notification-preferences',
              'Notification Settings',
            )
          }
        />
        <AuthRoute
          path="/ops/orders"
          component={() =>
            PageIFrameWrapper(
              '/ops/orders/order/order-list.php',
              'allow-scripts allow-same-origin allow-downloads allow-forms allow-popups allow-modals',
              [OPERATIONS_PORTAL],
              'ops-orders',
              'Installations',
              undefined,
              '&web_auth=true',
            )
          }
        />
        <AuthRoute path="/redirect" component={ExternalRedirect} />
        <Route path="*" component={GlobalErrorPage} />
      </Switch>
    </Viewport>
  );
};

export default withFirebaseNotifications(Routes);
